/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import React, {useEffect, useState} from 'react';
import {useRouter, usePathname} from 'next/navigation';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Toolbar,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import {signOut, useSession} from 'next-auth/react';
import {
  AccountCircle,
  ArrowDropDown,
  InsertDriveFile,
} from '@mui/icons-material';
import Instructions from 'components/search/Instructions';
import {useDrawerContext} from 'context/drawer';
import useWindowSize from 'hooks/getWindowSize';
import MenuIcon from '@mui/icons-material/Menu';
import NavSearch from './NavSearch';
import {
  AccountHeading,
  LogoutButton,
  MyBookmarksButton,
  MyDownloadsButton,
  MyFilesHeading,
  MyProfileButton,
  MyUploadsButton,
  UploadButton,
} from './NavButtons';

function Nav() {
  const {open, setOpen} = useDrawerContext();
  const router = useRouter();
  const pathname = usePathname();
  const {data: session, update} = useSession();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElFiles, setAnchorElFiles] = React.useState<null | HTMLElement>(
    null,
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenFilesMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFiles(event.currentTarget);
  };

  const handleCloseFilesMenu = () => {
    setAnchorElFiles(null);
  };

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const [screenWidth] = useWindowSize();

  const email = session?.user?.email || '';

  const handleUserProfileClick = () => {
    router.replace(`/profile?userEmail=${email}&tab=profile`);
    router.refresh();
    setMenuOpen(false);
  };

  const handleUserUploadsClick = () => {
    router.replace(`/profile?userEmail=${email}&tab=uploads`);
    router.refresh();
    setMenuOpen(false);
  };

  const handleUserDownloadsClick = () => {
    router.replace(`/profile?userEmail=${email}&tab=downloads`);
    router.refresh();
    setMenuOpen(false);
  };

  const handleUserBookmarksClick = () => {
    router.replace(`/profile?userEmail=${email}&tab=bookmarks`);
    router.refresh();
    setMenuOpen(false);
  };

  const handleLogout = () => {
    signOut();
    router.push('/');
    setMenuOpen(false);
  };

  const handleUpload = () => {
    router.push('/upload');
    setMenuOpen(false);
  };

  useEffect(() => {
    update();
  }, []);

  useEffect(() => {
    if (open && menuOpen) setMenuOpen(false);
  }, [open]);

  useEffect(() => {
    if (open && menuOpen) setOpen(false);
  }, [menuOpen]);

  const close = () => {
    setOpen(false);
  };

  // Check if the current route is the homepage
  const isHomePage = pathname === '/';
  return (
    <>
      <AppBar
        id="original-appbar"
        elevation={0}
        position="fixed"
        component="nav"
        color="default"
        sx={{
          backgroundColor: 'grays.dark',
          display: 'flex',
          my: '24px',
          boxShadow: '0px 10px 15px -5px rgba(255, 255, 255, 1)',
          width: 1,
          top: 0,
          left: 0,
        }}
      >
        {/* @ts-ignore */}
        {session && session?.error !== 'RefreshAccessTokenError' && (
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pl: 4,
              margin: 'auto',
            }}
          >
            <Box
              component="img"
              sx={{
                cursor: 'pointer',
                justifyContent: 'flex-start',
                height: 40,
                maxHeight: 200,
                maxWidth: 300,
              }}
              gridColumn="span 2"
              alt="DELVE Logo."
              src="/logo.svg"
              onClick={() => router.push('/')}
            />
            {!isHomePage && (
              <Box
                sx={{
                  mx: '32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                gridColumn="span 6"
              >
                <NavSearch />
              </Box>
            )}
            {isHomePage && (
              <Box
                sx={{
                  mx: '32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 0.6 * (screenWidth - 200),
                }}
                gridColumn="span 6"
              />
            )}
            {screenWidth >= 1366 && (
              <Box
                sx={{
                  pr: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
                gridColumn="span 2"
              >
                <UploadButton handleClick={handleUpload} />
                <Button
                  onClick={handleOpenFilesMenu}
                  sx={{
                    p: 0,
                    minWidth: 120,
                    textTransform: 'uppercase',
                    color: 'divider',
                  }}
                >
                  <InsertDriveFile
                    sx={{color: 'divider', mr: '8px', ml: '16px'}}
                  />
                  My Files
                  <ArrowDropDown
                    sx={{color: 'divider', ml: '8px', mr: '16px'}}
                  />
                </Button>
                <Menu
                  sx={{
                    mt: '52px',
                    '&.MuiMenu-root .MuiMenu-paper': {
                      backgroundColor: 'grays.dark',
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElFiles}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(anchorElFiles)}
                  onClose={handleCloseFilesMenu}
                >
                  <Box>
                    <MenuItem sx={{my: '-8px'}} onClick={handleCloseFilesMenu}>
                      <MyUploadsButton handleClick={handleUserUploadsClick} />
                    </MenuItem>
                    <MenuItem sx={{my: '-8px'}} onClick={handleCloseFilesMenu}>
                      <MyDownloadsButton
                        handleClick={handleUserDownloadsClick}
                      />
                    </MenuItem>
                    <MenuItem sx={{my: '-8px'}} onClick={handleCloseFilesMenu}>
                      <MyBookmarksButton
                        handleClick={handleUserBookmarksClick}
                      />
                    </MenuItem>
                  </Box>
                </Menu>
                <Tooltip title="Open settings">
                  <Button
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0,
                      textTransform: 'uppercase',
                      color: 'divider',
                    }}
                  >
                    <AccountCircle sx={{color: 'divider'}} />
                    <ArrowDropDown sx={{color: 'divider'}} />
                  </Button>
                </Tooltip>
                <Menu
                  sx={{
                    mt: '52px',
                    '&.MuiMenu-root .MuiMenu-paper': {
                      backgroundColor: 'grays.dark',
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box>
                    <MenuItem sx={{my: '-8px'}} onClick={handleCloseUserMenu}>
                      <MyProfileButton handleClick={handleUserProfileClick} />
                    </MenuItem>
                    <MenuItem sx={{my: '-8px'}} onClick={handleCloseUserMenu}>
                      <LogoutButton handleClick={handleLogout} />
                    </MenuItem>
                  </Box>
                </Menu>
              </Box>
            )}
            {screenWidth < 1366 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                gridColumn="span 2"
              >
                <IconButton
                  color="inherit"
                  aria-label="open side menu"
                  onClick={() => setMenuOpen(true)}
                  sx={{...(menuOpen && {display: 'none'})}}
                >
                  <MenuIcon sx={{color: 'divider'}} />
                </IconButton>
                <Drawer
                  sx={{
                    '&.MuiDrawer-paper': {
                      boxSizing: 'border-box',
                    },
                    '&.MuiDrawer-root .MuiDrawer-paper': {
                      marginTop: '24px',
                      height: 'calc(100% - 48px)',
                      backgroundColor: 'grays.dark',
                      overflowX: 'hidden',
                      borderWidth: 0,
                    },
                  }}
                  variant="temporary"
                  anchor="right"
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}
                >
                  <List>
                    <Divider sx={{mt: '8px', mb: '8px'}} />
                    <UploadButton handleClick={handleUpload} />
                    <Divider sx={{mt: '8px', mb: '8px'}} />
                    <Grid container direction="column">
                      <MyFilesHeading />
                      <MenuItem sx={{my: '-8px', ml: '12px'}}>
                        <MyUploadsButton handleClick={handleUserUploadsClick} />
                      </MenuItem>
                      <MenuItem sx={{my: '-8px', ml: '12px'}}>
                        <MyDownloadsButton
                          handleClick={handleUserDownloadsClick}
                        />
                      </MenuItem>
                      <MenuItem sx={{my: '-8px', ml: '12px'}}>
                        <MyBookmarksButton
                          handleClick={handleUserBookmarksClick}
                        />
                      </MenuItem>
                    </Grid>
                    <Divider sx={{mt: '8px', mb: '8px'}} />
                    <Grid container direction="column">
                      <AccountHeading />
                      <MenuItem sx={{my: '-8px', ml: '12px'}}>
                        <MyProfileButton handleClick={handleUserProfileClick} />
                      </MenuItem>
                      <MenuItem sx={{my: '-8px', ml: '12px'}}>
                        <LogoutButton handleClick={handleLogout} />
                      </MenuItem>
                    </Grid>
                    <Divider sx={{mt: '8px', mb: '8px'}} />
                  </List>
                </Drawer>
              </Box>
            )}
          </Toolbar>
        )}
      </AppBar>
      <Grid item xs={open ? 3 : 0} container>
        <Instructions close={close} open={open} />
      </Grid>
    </>
  );
}

export default Nav;
