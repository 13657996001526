/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

'use client';

import React, {lazy, useEffect, useState} from 'react';
import {Flow, Params, Settings} from 'react-chatbotify';
import {FEEDBACK_FORM, HELP_FORM, DATA_CONNECTION_FORM} from 'types/common';
import {chatbotStyle, settings, resourceOptionStyle} from 'styles/chatbot';
import {useRouter} from 'next/navigation';
import useSWRMutation from 'swr/mutation';
import {sendPostRequest} from 'utils/globals';
import {Button} from '@mui/material';
import {useSession} from 'next-auth/react';

const ChatBot = lazy(() => import('react-chatbotify'));

const header = {
  title: (
    <div style={{margin: 0, fontSize: 20, fontWeight: 'bold'}}>DELVE AI</div>
  ),
  showAvatar: true,
  avatar: '/auto_awesome.svg',
};

const chatbotSettings: Settings = {
  ...settings,
  header,
};

const helpOptions = [
  'Submit a Help Request',
  'Provide Feedback',
  'Recommend a Data Source',
  'Learn About DELVE',
];

export default function AskDelve() {
  const {data: session, update} = useSession();
  const [form, setForm] = useState({});
  const [displayResources, setDisplayResources] = useState<boolean>(false);
  const [resources, setResources] = useState<any>(null);
  const [resourceId, setResourceId] = useState<string | null>(null);
  const router = useRouter();
  const {trigger: getAnswer} = useSWRMutation(`/api/askDelve`, sendPostRequest);

  useEffect(() => {
    update();
  }, []);

  const submitQuestion = async (question: string) => {
    const answer = await getAnswer(question);
    let tempResources = null;
    if (
      answer?.data?.message?.sources &&
      answer?.data?.message?.sources?.length !== 0
    ) {
      tempResources = answer?.data?.message.sources;
      setResources(tempResources);
      setDisplayResources(true);
    } else {
      setResources(null);
      setDisplayResources(false);
    }
    return {text: answer.data.message.responseText, tempResources};
  };

  const handleResourceClick = (sourceId: string, params: Params) => {
    params.injectMessage('No problem!  Headed there now!');
    setTimeout(() => {
      router.push(`/file/${sourceId}?back=true`);
      setResourceId(null); // Clear resourceId after navigation
      params.injectMessage('Here we are!  Let me know if you need more help!');
    }, 2000);
  };

  const displayResourceList = (params: Params) => (
    <div>
      {resources &&
        resources.length !== 0 &&
        resources.map((resource) => (
          <Button
            key={resource.sourceId}
            onClick={() => handleResourceClick(resource.sourceId, params)}
            style={resourceOptionStyle}
            sx={{textTransform: 'none'}}
          >
            {resource.sourceTitle}
          </Button>
        ))}
    </div>
  );

  useEffect(() => {
    if (resources && resources?.length !== 0) setDisplayResources(true);
    else setDisplayResources(false);
  }, [resources]);

  const getPath = () => {
    if (displayResources && resourceId === null) return 'looking_now';
    if (displayResources && resourceId !== null) return 'repeat';
    return 'process_options';
  };

  const askDelveFlow: Flow = {
    start: {
      message:
        'Hi! How can I help you with DELVE?  Choose a path below, or ask me anything.',
      options: helpOptions,
      function: (params) => setForm({...form, question: params.userInput}),
      path: 'process_options',
    },

    // after user selects a link
    made_it: {
      message: `Here we are!  Let me know if you need more help!`,
      transition: {duration: 1000},
    },

    // after a user enters a question
    looking_now: {
      message: `Sure thing!  Looking now.... `,
      chatDisabled: true,
      transition: {duration: 50},
      path: 'answer_question',
    },

    // retrieve answer text and resources from LLM
    answer_question: {
      message: async (params) => {
        const {text, tempResources} = await submitQuestion(params.userInput);
        setResources(tempResources);
        return text;
      },
      transition: {duration: 50},
      path: () => {
        return 'show_options';
      },
    },

    // show generic help options
    show_options: {
      chatDisabled: false,
      message: displayResources
        ? 'Related Resources'
        : `Maybe these items will help?`,
      options: !displayResources ? helpOptions : undefined,
      component: displayResources ? displayResourceList : undefined,
      path: getPath(),
    },

    // process selection of generic help option
    process_options: {
      chatDisabled: false,
      transition: {duration: 50},
      path: (params: Params) => {
        let link = '';
        switch (params.userInput) {
          case 'Submit a Help Request':
            link = HELP_FORM;
            break;
          case 'Provide Feedback':
            link = FEEDBACK_FORM;
            break;
          case 'Recommend a Data Source':
            link = DATA_CONNECTION_FORM;
            break;
          case 'Learn About DELVE':
            link = '../policy?tab=about';
            break;
          default:
            return 'looking_now';
        }
        params.injectMessage('No problem!  Headed there now!');
        setTimeout(() => {
          if (link === '../policy?tab=about') router.push(link);
          else window.open(link);
          params.injectMessage(
            'Here we are!  Let me know if you need more help!',
          );
        }, 2000);
        return 'repeat';
      },
    },

    // looking for data repeat loop
    repeat: {
      path: 'looking_now',
    },
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      {isLoaded && session && session?.error !== 'RefreshAccessTokenError' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: `calc(20vh)`,
          }}
        >
          <ChatBot
            settings={chatbotSettings}
            styles={chatbotStyle}
            flow={askDelveFlow}
          />
        </div>
      )}
    </>
  );
}
